.popup-content {
    width: 95%;
}

.dark .popup-content {
    background: #313943;
    border: 1px solid #000;
}

.modalContainer {
    padding: 25px 50px 25px 50px;
}

.modalContainer > .close {
    background-color: #fff;
    border: 1px solid #cfcece;
    border-radius: 18px;
    cursor: pointer;
    font-size: 22px;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark .modalContainer > .close {
    background: #1e1e1e;
    border: 1px solid #000;
    color: #fff;
}

.dark .content {
    background: #1e1e1e;
    color: #eee;
}

.dark .content .instanceInfo .title {
    color: #eee;
}

.dark .header .moduleName, .dark .header .clientName {
    color: #eee;
}

.dark .status.offline {
    background-color: #fff;
    color: #1e1e1e;
}

@media (max-width: 550px) {
    .modalContainer {
        padding: 20px;
    }   
}

@media (max-width: 450px) {
    .modalContainer {
        padding: 15px;
    }   
}