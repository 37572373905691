.moduleList {width: 100%;}
.moduleList tbody tr {background: #fff; border: 1px solid #e4e3e2;}
.moduleList tr:nth-child(even) {background: #f8f8f8;}
.moduleList th, .moduleList td {padding:10px 25px;}
.moduleList th {color: #fff; background: #444;}
.moduleList td {text-align: center;font-weight: normal;}
.moduleList a {color: #797979;}

.dark .moduleList tbody tr {background: #313943; border: 1px solid #4f575f;}
.dark .moduleList tr:nth-child(even) {background: #343d48;}
.dark .moduleList th {color: #fff;background: #242930;}
.dark .moduleList a {color: #eee;}

@media (max-width: 576px) {
    .moduleList th, .moduleList td {padding: 10px;}
}