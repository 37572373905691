.dark .InovuaReactDataGrid--theme-default-light {
    background-color: #313943;
    border: 1px solid #4f575f;
    color: #9ba7b4;
    fill: #9ba7b4;
}

.dark .InovuaReactDataGrid__row--even,
.dark .InovuaReactDataGrid__row--no-zebra,
.dark .InovuaReactDataGrid__header {
    background: #313943;
}

.dark .InovuaReactDataGrid__row--odd {
    background: #343d48;
}

.dark .InovuaReactDataGrid__cell--show-border-bottom,
.dark .InovuaReactDataGrid__footer-row-cell--show-border-bottom,
.dark .InovuaReactDataGrid__locked-row-cell--show-border-bottom,
.dark .InovuaReactDataGrid__header,
.dark .InovuaReactDataGrid__header-wrapper__fill {
    border-bottom: 1px solid #4f575f;
}

.dark .InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row--no-zebra:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover,
.dark .InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row--odd:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover,
.dark .InovuaReactDataGrid--show-hover-rows .InovuaReactDataGrid__row--even:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty) .InovuaReactDataGrid__row-hover-target:hover {
    background: #3c4557;
    color: #c5cae9;
}