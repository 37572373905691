#instances ion-toggle {
    width: 48px;
    height: 24px;
    padding-left: 0;

    --background: #eb445a;
    --background-checked: #2dd36f;
  
    --handle-background: #fff;
    --handle-background-checked: #fff;    
    --handle-height: 22px;
    --handle-width: 22px;
    --handle-spacing: 1px;
}

#instances .InovuaReactDataGrid__column-header--show-border-left, #instances .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border-left: none;
}