.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header .moduleNameCircle {
    background-color: #c8facd;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    font-size: 28px;
    color: #005249;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.header .moduleName {
    color: #797979;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
}

.header .clientName {
    color: #707070;
    font-size: 18px;
}

.headerInfo {
    margin-top: 6px;
}

.headerInfo span {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 11px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
}

.status {
    border-radius: 8px;
    color: #fff;
    padding: 2px 8px;
    font-size: 11px;
    text-transform: uppercase;
}

.headerInfo .module {
  background-color: #707070;
}

.headerInfo .version {
    background-color: #0098d8;
}

.status.offline {
    background-color: #222428;
}

.status.online {
    background-color: #2dd36f;
}

.status.error {
    background-color: #eb445a;
}

.status.warning {
    background-color: #ffc409;
}

.content {
    background-color: #f4f5f7;
    border-radius: 15px;
    padding: 18px;
    margin-top: 30px;
    color:#797979;
    font-size:14px;
    font-weight: 700;
}

.content .instanceInfo td {
    padding: 5px 10px;
}

.content .instanceInfo .title {
    width: 170px;
    text-align: right;
    color: #5c5a59;
    font-size: 13px;
    font-weight: normal;
}

.content .instanceInfo .flex {
    display: flex;
    justify-content: left;
    align-items: center;
}

.content .icon {
    margin-left: 7px;
    width: 16px;
}

.breakAll {
    word-break: break-all;
}

ion-icon {
    font-size: 20px;
}

@media (max-width: 550px) {
    .content {
        padding: 10px;
    }
    .content .instanceInfo .title {
        width: 130px;
    }
}

@media (max-width: 450px) {
    .content {
        margin-top: 10px;
        font-size: 12px;
    }
    .content .instanceInfo td {
        padding: 5px;
    }
    .content .instanceInfo .title {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .header .moduleNameCircle {
        display: none;
        opacity: 0;
        width: 0;
        height: 0;
    }
}